import * as React from "react";
import Container from "@mui/material/Container";
import logo from "../../../images/logo/logo.png";
import { Button } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import MailchimpForm from "../subscribe/mailchimp";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
      <Container maxWidth="xl" className="footer-main">
        <div className="footer-container">
          <div className="footer-container-section">
            <Link className="footer-logo-link" to="/">
              <img src={logo} className="footer-logo" alt="logo" />
            </Link>
            <Button
              href="https://www.seetickets.com/event/edinburgh-psych-fest-2025/various-venues/3138199"
              color="primary"
              size="large"
              variant="outlined"
              className="ticket-header-button"
              sx={{
                fontSize: "16px",
                borderWidth: "3px",
                margin: "20px 0",
                borderRadius: "0px"
              }}
              target="_blank"
            >
              Buy Tickets Now
            </Button>
          </div>
          <div className="footer-container-section">
            <div className="footer-social-container">
              <h3>Get in touch</h3>
              <a
                className="email-link"
                href="mailto:info@edinburghpsychfest.com"
                target="_blank"
                rel="noreferrer"
              >
                info@edinburghpsychfest.com
              </a>

              <div className="social-icons">
                <a
                  href="https://www.facebook.com/edinburghpsychfest/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.instagram.com/edinburghpsychfest/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://twitter.com/edinburgh_psych"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-container-section">
            <div className="subscribe-inner">
              <h3>Join our mailing list</h3>
              <MailchimpForm />
            </div>
          </div>
        </div>
        <div className="footer-section-bottom">
          <div className="footer-bottom">
            <p>© 2025 Edinburgh Psych Fest</p>
          </div>
        </div>
      </Container>
    </footer>
  );
}
export default Footer;
