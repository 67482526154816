import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import LineUpCard from "./line-up-card";

const artists = [
  {
    name: "Do Nothing",
    sort: "Do Nothing",
    link: "https://donothingband.com/",
    img: "donothing.jpg",
  },
  {
    name: "Adore",
    sort: "Adore",
    link: "https://open.spotify.com/artist/4BwY0FjNdkoWef8kixy54M?si=8jEI8-A4SJetLFgZRGhktQ",
    img: "dore.jpeg",
  },
  {
    name: "Deary",
    sort: "Deary",
    link: "https://www.dearyband.com/",
    img: "deary.jpeg",
  },
  {
    name: "Honeyglaze",
    sort: "Honeyglaze",
    link: "https://honeyglaze.ochre.store/",
    img: "honeyglaze.jpg",
  },
  {
    name: "Crocodiles",
    sort: "Crocodiles",
    link: "https://crocodilesband.bandcamp.com/merch",
    img: "Crocodiles.jpg",
  },
  {
    name: "Bubble Tea and Cigarettes",
    sort: "Bubble Tea and Cigarettes",
    link: "https://bubbleteaandcigarettes.com/",
    img: "bubble.jpg",
  },
  {
    name: "Deadletter",
    sort: "Deadletter",
    link: "https://open.spotify.com/artist/4MfUQ99z2kgMnl9hAwffBx?si=R26ivke6QhmboUyZ9Eyhjw",
    img: "deadletter.jpg",
  },
  {
    name: "George Clanton",
    sort: "George Clanton",
    link: "https://www.100percentelectronica.com/pages/george-clanton?srsltid=AfmBOoqNFIZBMwPsUr9ktsfw4b4yPpquANgidxTetwmHcQEpJq2SqguE",
    img: "georgeclanton.jpg",
  },
  {
    name: "Goblyns",
    sort: "Goblyns",
    link: "https://goblynsband.com/",
    img: "goblyns.jpg",
  },
  {
    name: "Mojo & The Kitchen Brothers",
    sort: "Mojo & The Kitchen Brothers",
    link: "http://www.instagram.com/mojoandthekitchenbrothers",
    img: "mojo.jpeg",
  },
  {
    name: "Blind Yeo",
    sort: "Blind Yeo",
    link: "https://www.lostmap.com/blind-yeo",
    img: "blindyeo.png",
  },
  {
    name: "Swiss Portrait",
    sort: "Swiss Portrait",
    link: "https://www.swissportrait.co.uk/",
    img: "swiss.jpg",
  },
  {
    name: "Linzi Clark",
    sort: "Linzi Clark",
    link: "https://linziclarkmusic.bandcamp.com/merch",
    img: "linzi.jpg",
  },
  {
    name: "BIN JUICE",
    sort: "BIN JUICE",
    link: "https://linziclarkmusic.bandcamp.com/merch",
    img: "binjuice.png",
  },
];

const sortedArtists = artists.sort((a, b) => a.sort.localeCompare(b.sort));

function LineUp() {
  return (
    <Container className="LineUp" maxWidth="xl">
      <Box sx={{ flexGrow: 1, flexWrap: "wrap", display: { xs: "flex" } }}>
        {sortedArtists.map((artist) => (
          <LineUpCard key={artist.name} artist={artist} />
        ))}
      </Box>
    </Container>
  );
}
export default LineUp;
