import * as React from "react";
import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";

function Info() {
  return (
    <Container className="info" maxWidth="xl">
      <div className="info-content">
        <div className="text-section">
          <Typography className="title" variant="h6">
            Edinburgh PSYCH FEST 2025 – FAQ / INFO
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Where
          </Typography>
          <Typography className="text">
            Multi-venue festival taking place across Edinburgh.
          </Typography>
          <Typography className="text">
            Ticket gains access to all venues. ***
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            When
          </Typography>
          <Typography className="text">Sunday 31st August 2025</Typography>
          <Typography className="text">12pm - 11pm.</Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Live music line-up
          </Typography>
          <Typography className="text">
            {"Click "} <Link to="/line-up">here</Link>{" "}
            {" to visit line-up A-Z page."}
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Food Stalls
          </Typography>
          <Typography className="text">
            A selection of food stalls will be available on the day.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Accessibility
          </Typography>

          <Typography className="text">
            Accessibility information can be found on participating venues'
            websites.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Tickets
          </Typography>
          <Typography className="text">
            Limited £24.50 early bird tickets Sold Out. Advance £30 Tickets on
            sale now (link to tickets)**
          </Typography>
          <Typography className="text">
            {"Tickets on sale now "}{" "}
            <Link
              target="_blank"
              to="https://www.seetickets.com/event/edinburgh-psych-fest-2025/various-venues/3138199"
            >
              here
            </Link>
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Wristband exchange
          </Typography>
          <Typography className="text">
            More information on wristband collection will be released closer to
            the time.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Ages
          </Typography>
          <Typography className="text">
            14 + (Ages: 14+ - Under 16's Must Be Accompanied By An Adult and
            Offsite by 11.59 PM)
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Getting to the festival
          </Typography>
          <Typography className="text">
            All venues will be accessible by road.
          </Typography>
          <Typography className="text">
            Your wristband gains access to all stages.***
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="text">* Line-up subject to change</Typography>
          <Typography className="text">** plus booking fees</Typography>
          <Typography className="text">
            *** Venue capacities are based on first come first serve so please
            arrive early for specific acts.
          </Typography>
        </div>
        <div className="text-section">
          <Typography className="title" variant="h6">
            Contact
          </Typography>
          <Typography className="text">info@edinburghpsychfest.com</Typography>
        </div>
      </div>
    </Container>
  );
}
export default Info;
