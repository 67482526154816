export const themeOptions = {
  palette: {
    type: "light",
    primary: {
      main: "#232320",
    },
    secondary: {
      main: "#cdc6a8",
    },
    background: {
      default: "#232320",
    },
    warning: {
      main: "#e45d3e",
    },
    divider: "#5a9260",
  },
  spacing: 12,
  shape: {
    borderRadius: 6,
  },
};
