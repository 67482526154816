import { Outlet } from "react-router-dom";
import ResponsiveAppBar from "../app-bar/app-bar";
import Footer from "../footer/footer";

function Layout() {
  
  return (

      <div className="layout">
        <ResponsiveAppBar />
        <div className="main-content">
          <Outlet />
        </div>
        <Footer />
      </div>
    
  );
}

export default Layout;
