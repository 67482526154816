import { Button, Container } from "@mui/material";
import lineup from "../../images/assets/lineupmain.jpg";
import gifEpf from "../../images/logo/EPF 2024 GIF - LANDSCAPE.gif";

function Home() {
  return (
    <div className="home">
      <div className="home-lineup">
          <div className="lineup-container">
            <img src={lineup} className="lineup-image" alt="lineup" />
          </div>
          <div className="lineup-container">
            <img src={gifEpf} className="gif-image" alt="lineup" />
          </div>

      </div>

      <div className="home-tickets">
        <Container maxWidth="xl">
          <div className="home-tickets-container">
            <h2>Tickets on sale now</h2>
            <h2>Sunday 31st August</h2>
            <Button
              href="https://www.seetickets.com/event/edinburgh-psych-fest-2025/various-venues/3138199"
              color="secondary"
              size="large"
              variant="outlined"
              sx={{
                marginTop: "20px",
                fontSize: "24px",
                padding: "10px 80px",
                borderWidth: "3px",
                borderRadius: "0px",
              }}
              target="_blank"
              className="ticket-header-button"
            >
              Buy Tickets
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
}
export default Home;
