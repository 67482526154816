import { Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import { inputLabelClasses } from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)({
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#232320",
    borderWidth: "3px",
    borderRadius: "0px",
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: "#232320",
      borderWidth: "3px",
      borderRadius: "0px",
    },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: "#232320",
      borderWidth: "3px",
      borderRadius: "0px",
    },
  [`& .${outlinedInputClasses.input}`]: {
    color: "#232320",
  },
  [`&:hover .${outlinedInputClasses.input}`]: {
    color: "#232320",
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
    {
      color: "#232320",
    },
  [`& .${inputLabelClasses.outlined}`]: {
    color: "#232320",
  },
  [`&:hover .${inputLabelClasses.outlined}`]: {
    color: "#232320",
  },
  [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    color: "#232320",
  },
});

const CustomForm = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
      onValidated({
        EMAIL: email,
      });
  };

  useEffect(() => {
    if (status === "success") clearFields();
  }, [status]);

  const clearFields = () => {
    setEmail("");
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <form className="subscribe-form" onSubmit={(e) => handleSubmit(e)}>
      <div className="subscribe-inner">
        <div>
          <StyledTextField
            label="Email"
            onChange={handleEmailChange}
            type="email"
            required
            name="email-input"
            id="email-input"
            fullWidth
            sx={{ color: "white", marginTop: "20px" }}
            defaultValue={email}
          />
          <Button
            color="primary"
            size="large"
            variant="outlined"
            type="submit"
            sx={{
              margin: "20px 0",
              fontSize: "16px",
              borderWidth: "3px",
              borderRadius: "0px",
            }}
            fullWidth
            disabled={status === "sending"}
          >
            Subscribe
          </Button>
          {status === "sending" && (
            <div className="mc__alert mc__alert--sending">sending...</div>
          )}
          {status === "error" && (
            <div
              className="mc__alert mc__alert--error"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div
              className="mc__alert mc__alert--success"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>
      </div>
    </form>
  );
};

const MailchimpForm = () => {
  const url = `https://nowwave.us14.list-manage.com/subscribe/post?u=a2a2ee1820adf5dd039fc9b02&amp;id=fb223242b6&amp;f_id=007f8ae0f0`;

  return (
    <div className="mc__form-container">
      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <CustomForm
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default MailchimpForm;
